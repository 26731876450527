<template>
    <div class="clientcabin clientcabin-blog">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/1.png"
                        width="100%"
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        There’s No Such Thing As Bad Publicity: Why Brands Work
                        Better When They Tell Their Own Stories
                    </p>
                    <v-card
                        class="blueish black--text px-6 py-5 rounded-lg my-6 elevation-0"
                    >
                        <p class="font-weight-bold">Key takeaways</p>
                        <ul>
                            <li>
                                Consumer engagement heightens brand awareness -
                                and publicity can drive engagement whether it’s
                                positive or negative.
                            </li>
                            <li>
                                Brands can take advantage of consumer attention
                                to spread the word about their values, ideals,
                                and goals.
                            </li>
                            <li>
                                Conversation is crucial, and your brand needs to
                                be involved. Once the attention is on you, it’s
                                the perfect time to change the narrative. How?
                                Your story, told your way…
                            </li>
                        </ul>
                    </v-card>
                    <p>
                        There’s more than one way to stack an egg. Or is it
                        crack an egg? Anyway, the point is this: if it’s broken
                        eggs you want, it’s up to you as to how you’ll get it
                        done. Building a successful brand is no different -
                        there are a lot of different tactics that can help you
                        get there. Which route will you take?
                    </p>
                    <p>
                        If you want your brand to catch fire, there’s no Golden
                        Ticket straight to the chocolate factory. It takes real
                        work, from collecting social media followers to creating
                        partnerships with influencers and crafting campaigns
                        that are so good they go viral.
                    </p>
                    <p>
                        Yet, here’s something most brands don’t put nearly
                        enough work into:
                        <b>telling their own stories</b>
                        .
                    </p>
                    <p>
                        Why did you start? What do you stand for? How can you
                        (how will you) make the world better? Your audience
                        demands to know the answers to these questions - and
                        only you can tell them.
                    </p>
                    <p>
                        Unless your brand was founded a thousand years ago,
                        chances are it’s still working hard at cementing its
                        reputation. These things don’t happen overnight!
                    </p>
                    <p>
                        We can’t please every consumer, of course. But don’t let
                        negativity shake you. In fact, negative press can
                        actually help your brand if you know how to handle it.
                        Think about it: people who are angry with you are
                        already talking about you online.
                    </p>
                    <p>
                        And if they’re spending time and energy complaining
                        about your brand, they’re also spending time and energy
                        <u>promoting</u>
                        your brand. In other words, your brand’s name is online
                        - and on their minds.
                    </p>
                    <p>
                        So instead of fighting back against negative comments
                        and reviews, why not take advantage of this free
                        advertisement?
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        What Does The Phrase "There’s No Such Thing As Bad
                        Publicity" Mean?
                    </p>
                    <p>
                        Even a negative can lead to a positive. Many brands have
                        come to this conclusion - drawing on negativity to drive
                        engagement.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Don’t think in terms of scandals. If a brand has some
                        bad press or goes through some rough times, that doesn’t
                        mean it’s permanently damaged. It also doesn’t mean that
                        every person reading about your brand will hate you!
                    </p>
                    <p>
                        In fact, these might be the very keywords your audience
                        is using, but they’re so specific, niche, or new that
                        the masses haven’t caught on.
                    </p>
                    <p>
                        People have to be
                        <i>told</i>
                        what to play, what to consume, and what to think – and
                        the mass media has always been extremely important in
                        this process.
                    </p>
                    <p>
                        Yet, your breakthrough will come as you understand this:
                        to sell a product to the public, it’s not enough to put
                        information about it in the media. It has to be the
                        <i>right</i>
                        information - in the right way. Even today, these words
                        are as true as ever.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        What If You Could Get The Good Kind Of Publicity?
                    </p>
                    <p>
                        A good brand story is built on the foundation of a great
                        brand. Good publicity is built on the foundation of a
                        great
                        <i>news story</i>
                        . See the connection?
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        “There’s no such thing as bad publicity.” Wise words.
                        Actually, any public discussion about your brand or
                        company can be helpful if you manage it right.
                    </p>
                    <p>
                        People want to talk about brands and companies they care
                        about. If you do something that gets people talking
                        about your brand (never mind what they’re saying), then
                        you’ve succeeded in putting yourself
                        <b>into the conversation</b>
                        . Think of it this way; people are always going to talk
                        about what happened - so why not make sure they’re
                        telling your side while they’re at it?
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        You’ve Got To Be Part Of The Conversation
                    </p>
                    <p>
                        You’re not always going to be beckoned into the
                        conversation with open arms - it’s your move. This means
                        that every time someone mentions your brand, you need to
                        respond.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        You can’t just sit there and be quiet while another
                        company, media or person tells your story for you.
                        Change the conversation.
                    </p>
                    <p>There are a few key ways to do this…</p>
                    <ul>
                        <li class="mb-4">Be authentic and genuine</li>
                        <li class="mb-4">Be consistent</li>
                        <li class="mb-4">Be relevant</li>
                        <li class="mb-4">Be timely</li>
                    </ul>
                    <p>
                        As you improve at telling your brand’s story, the number
                        of eager listeners will skyrocket. But how do you create
                        a compelling, personal narrative? What’s the big secret
                        that will make audiences listen?
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Top Brands Tell Stories To Build Trust And Engagement
                    </p>
                    <p>
                        Word of mouth has always helped consumers decide which
                        products and services to buy.
                    </p>
                    <p>Today, Google has made the decision even easier.</p>
                    <p>
                        Your brand needs to craft content and share it with
                        customers through multiple channels. More than anything
                        else, your relationship with customers has to be
                        <b>authentic</b>
                        - and your story has to evoke trust.
                    </p>
                    <p>
                        Want an example? Consider how
                        <b>Southwest Airlines</b>
                        manages it.
                    </p>
                    <ul>
                        <li class="mb-4">
                            Instead of trying too hard with clever quips or
                            funny videos like most airlines do online ( failing
                            miserably in the process!), Southwest simply sticks
                            with what they do best: being friendly!
                        </li>
                    </ul>
                    <p>
                        The reason they’ve built up such a strong reputation
                        over time is that they’re
                        <i>genuine</i>
                        when interacting with customers online - even when
                        responding publicly to angry passengers over canceled
                        flights…
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/5.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        No matter the tone of public discourse - positive,
                        negative, or in-between - your response can reveal a lot
                        about what your brand represents.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        How Telling Your Own Story Helps Brands Thrive
                    </p>
                    <p>
                        Your brand wants to
                        <b>win</b>
                        the marketing game - but simply buying ads and hoping
                        for the best isn’t the way to go. It requires
                        <b>authenticity, relevance and timeliness</b>
                        - and
                        <i>storytelling</i>
                        is a river that runs straight to them.
                    </p>
                    <p>
                        By telling your own story, you’re telling people about
                        the values that are important to you. You’re also
                        showing them
                        <b>what kind</b>
                        of business you are and
                        <b>why</b>
                        they should do business with you.
                    </p>
                    <p>
                        After all, telling a story is doing what brands have
                        always done through advertising: selling a product or
                        service and building brand loyalty. For instance:
                    </p>
                    <ul>
                        <li class="mb-4">
                            <b>Dove</b>
                            wanted to share its message about beauty being “for
                            women of all shapes and sizes.” They didn’t just buy
                            an ad on television or in magazines, though - they
                            <a
                                href="https://www.thinkwithgoogle.com/intl/en-emea/marketing-strategies/video/dove-real-beauty-sketches/"
                                target="_blank"
                            >
                                created a video campaign
                            </a>
                            demonstrating how perceptions of real beauty differ
                            from person to person. The result?
                            <b>One of the most watched and shared online ads</b>
                            of all time! Now
                            <i>that’s</i>
                            going viral.
                        </li>
                        <li class="mb-4">
                            <b>Airbnb</b>
                            grew tired of hearing about how their service was
                            ruining neighborhoods all around the world. In
                            response, their video ads showcased how welcoming
                            locals were when hosting travelers from all over the
                            globe - promoting international friendship in turn.
                        </li>
                    </ul>

                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/6.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        When telling your story, you’re the one holding the
                        pencil. Craft a message that represents your brand - and
                        make sure the conversation revolves around it.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Give People What They Want - Exactly When They Want It
                    </p>
                    <p>
                        If people are already following your brand, you’re
                        probably no stranger to the idea of creating content.
                        This could be anything from blog posts and news articles
                        or videos on YouTube to Facebook posts - you name it!
                    </p>
                    <p>
                        Yet, if you want your fanbase to grow - and your fans to
                        stay loyal - then your fans will want something too. If
                        you can figure out what that “something” is, you’re onto
                        something else…
                    </p>
                    <p>
                        Consumer interest isn’t automatic - your brand has to
                        actively tell a story. Do you have a story to tell? Then
                        you’ve already won half the battle. Of course, it can’t
                        just be any old tired yarn. You have to tailor the story
                        to your specific audience. Whatever they’re yearning
                        for, they need to see in your brand.
                    </p>
                    <p>
                        Remember, you have to tell your
                        <b>own</b>
                        story. Not someone else’s. Telling the truth is the best
                        way to stay consistent, don’t you think? A consistent
                        message is more trustworthy. When people trust you,
                        they’ll be more inclined to buy from you. It’s that
                        simple.
                    </p>
                    <p>
                        You don’t want your audience to tune out. That means you
                        have to focus on the things that matter most:
                    </p>
                    <ul>
                        <li class="mb-4">
                            <b>consistency</b>
                            in delivery
                        </li>
                        <li class="mb-4">
                            <b>authenticity</b>
                            in content
                        </li>
                        <li class="mb-4">
                            <b>being true to yourself</b>
                            at all times
                        </li>
                    </ul>
                    <p>
                        Your brand’s name is on the line: you can either tell
                        your own story or leave it up to the public to tell your
                        stories for you. But when you choose to create content
                        that shares your own story, values, and identity, you
                        get an opportunity to connect with people. That’s how
                        brands work better - and that’s where
                        <b>our campaigns</b>
                        come in handy.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/bad-publicity/7.png"
                        width="100%"
                    />
                    <p class="mt-6 font-weight-bold text-center">
                        Your story is waiting to be told. {{ company }} can help
                        you there by providing you with all the tools and
                        strategies you need to succeed online.
                        <br />
                        <br />
                        <router-link to="/info">Book a free call</router-link>
                        with us today to tell us about your business goals.
                        We’ll show you exactly how you can achieve them - with
                        our help!
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class BadPublicity extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>
